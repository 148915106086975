import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import IconButton from '@mui/material/IconButton'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

type Props = {
  label: string
  value: string
  endAdornment: boolean
  disabled: boolean
  role?: string
}

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#DADADA',
    fontWeight: '800',
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0px 1px 25px 0px #0000001A',
    top: '5px',
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'justify',
    padding: '9px 15px',
  },
}))

export default function Input({
  label, value, endAdornment, disabled, role,
}: Props) {
  return (
    <>
      <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
        {label}
      </Typography>
      <CssTextField
        value={value}
        fullWidth
        disabled={disabled}
        InputProps={{
          endAdornment: (
            endAdornment && (
            <InputAdornment position="start">
              <CustomTooltip
                placement="top-start"
                title="Veuillez contacter votre manager ou
              un administrateur si cette information vous semble erronée ou si vous souhaitez la modifier."
              >
                <IconButton>
                  <LockOutlinedIcon />
                </IconButton>
              </CustomTooltip>
            </InputAdornment>
            )

          ),
        }}
      />
    </>
  )
}

const defaultProps = {
  role: '',
}

Input.defaultProps = defaultProps
