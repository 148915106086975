import { persistStore } from 'redux-persist'
import { configureStore } from '@reduxjs/toolkit'
import persistedReducer, { rootReducer } from 'reducers'
import errorInterceptorMiddleware from './interceptorRedux/errorInterceptorMiddleware'

const store = configureStore({
  reducer: persistedReducer as unknown as typeof rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(errorInterceptorMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
})

const persistor = persistStore(store)

export {
  store,
  persistor,
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
