import { useAppDispatch, useAppSelector } from 'utils'
import { useEffect, useState } from 'react'
import { getTeam, removeMember, addMember } from 'reducers/teams/teams.thunk'
import SimpleButton, { ButtonStyle } from 'components/button'
import Modal from 'components/modal'
import ModalSearch from 'components/modalSearch'
import AddIcon from '@mui/icons-material/Add'
import { getUsersInfo } from 'reducers/userInfo/userInfo.thunk'
import { Box } from '@mui/material'
import TableTeam from './table'

export default function MyTeams() {
  const dispatch = useAppDispatch()
  const { userInfo, usersInfo } = useAppSelector(state => state.userinfo)
  const { team } = useAppSelector(state => state.teams)
  const [myTeamList, setMyTeamList] = useState([])
  const [memberId, setMemberId] = useState<string>('')
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

  useEffect(() => {
    if (userInfo && userInfo.teamId) {
      dispatch(getTeam(userInfo?.teamId))
    }
  }, [userInfo])

  useEffect(() => {
    if (team) {
      const usersIdList: string[] = team?.members.map(item => item.id)
      dispatch(getUsersInfo(usersIdList))
    }
  }, [team])

  useEffect(() => {
    const updatedMyTeamList = team?.members?.map(item => {
      const matchingItem = usersInfo?.find(secondItem => secondItem.id === item.id)

      if (matchingItem) {
        return { ...item, lastName: matchingItem.lastName, firstName: matchingItem.firstName }
      }
      return item
    })
    setMyTeamList(updatedMyTeamList)
  }, [usersInfo])

  const openDeleteModal = (id: string) => {
    setMemberId(id)
    setShowRemoveModal(true)
  }

  const handleRemoveMember = (id: string) => {
    dispatch(removeMember(id)).then(res => {
      if (res.type.includes('fulfilled')) {
        setShowRemoveModal(false)
        dispatch(getTeam(userInfo?.teamId))
      }
    })
  }

  const openSearchModal = () => {
    setShowSearchModal(true)
  }

  const handleAddMember = () => {
    dispatch(addMember(memberId, team.id)).then(res => {
      if (res.type.includes('fulfilled')) {
        setShowSearchModal(false)
        dispatch(getTeam(team.id))
      }
    })
  }

  const handleSelect = (e, value) => {
    setMemberId(value?.id)
  }

  return (
    <div className="page teams">
      <div className="header d-flex flex-column">
        <div className="title">Mon équipe</div>
        <div className="wrapper mt-24">
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <div className="title">{team?.name}</div>
            <SimpleButton
              text="Ajouter un membre"
              startIcon={<AddIcon />}
              sx={{ height: '32px' }}
              onClick={() => openSearchModal()}
              style={ButtonStyle.light}
              disabled={userInfo?.teamId === null}
            />
          </Box>

          <TableTeam members={myTeamList} onDelete={openDeleteModal} />
        </div>
      </div>
      <Modal
        title="Retrait du membre"
        bodyText="Êtes-vous sûr de vouloir retirer ce membre de votre équipe ?"
        handleClose={() => setShowRemoveModal(false)}
        onButtonClick={() => handleRemoveMember(memberId)}
        buttonStyle={ButtonStyle.error}
        open={showRemoveModal}
        buttonText="Oui, retirer"
      />
      <ModalSearch
        title={`Ajout d'un membre à l'équipe ${team?.name} ?`}
        handleClose={() => setShowSearchModal(false)}
        onButtonClick={() => handleAddMember()}
        buttonStyle={ButtonStyle.primary}
        open={showSearchModal}
        buttonText="Ajouter le membre"
        handleSelect={(e, value) => handleSelect(e, value)}
      />
    </div>
  )
}
