import { auth } from '@osrdata/app_core'
import 'App.scss'
import {
  ReactElement, StrictMode, Suspense, useEffect, useState,
} from 'react'
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom'
import TabList from 'components/tabList'
import navTabElements from 'utils/navTabElements'
import Loader from 'components/loader'
import TopBar from 'components/topBar'
import { terms } from 'assets/localize/terms'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { RootState } from 'reducers/store'
import { hideSnackbar } from 'reducers/app'
import { getUserInfo, getManagerInfo } from 'reducers/userInfo/userInfo.thunk'
import Snackbar from 'components/snackbar'
import {
  AccessDenied, Agenda, Leaves, LeavesManagement, Profile, Settings, MyTeam, Teams,
} from 'pages'

const ACCESS_PERM = 'app-rh::ACCESS'

/** Externalize router logic in its own component
 * in order to use useLocation hook
 */
function Router() {
  return (
    <Routes>
      <Route element={<Agenda />} path="/agenda" />
      <Route element={<Leaves />} path="/absences" />
      <Route element={<LeavesManagement />} path="/gestion-absences" />
      <Route element={<Profile />} path="/profile" />
      <Route element={<MyTeam />} path="/mon-equipe" />
      <Route element={<Teams />} path="/les-equipes" />
      <Route path="*" element={<Navigate to="/agenda" />} />
    </Routes>
  )
}

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const {
    isLogged, appPermissions, isLoading, account,
  } = useAppSelector(state => state.user)
  const { displaySnackbar, snackbarMessage, snackbarSeverity } = useAppSelector((state: RootState) => state.app) || {}
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      setAccessDenied(!appPermissions.includes(ACCESS_PERM))
      dispatch(getUserInfo(account.id))
    }
  }, [isLogged, appPermissions])

  const renderApp = () => {
    if (isLoading) return <Loader message={terms.Common.loading} standalone />
    if (accessDenied) return <AccessDenied />

    return (
      <Router />
    )
  }

  if (!isLoading && !isLogged) return null

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          {!isLoading
          && (
          <TopBar
            innerComponent={<TabList tabs={navTabElements} />}
            hasAccess={!accessDenied}
          />
          )}
          <div id="app">{renderApp()}</div>
          <Snackbar
            message={snackbarMessage}
            severity={snackbarSeverity}
            displaySnackbar={displaySnackbar}
            handleClose={() => dispatch(hideSnackbar())}
          />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
