import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'

type Props = {
  handleChange: (string) => void,
  value: string | null,
  label: string
}

export default function DataPickerComponent({ handleChange, value, label }: Props) {
  return (
    <FormControl fullWidth>
      <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
        {label}
      </Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value="MORNING">Matin</MenuItem>
        <MenuItem value="AFTERNOON">Après-midi</MenuItem>
      </Select>
    </FormControl>
  )
}
