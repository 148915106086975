import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Leave, LeaveManaged } from './types'

const myLeaves: Leave[] = []
const myTeamLeaves: LeaveManaged[] = []
const initialState = {
  myLeaves,
  myTeamLeaves,
  loading: false,
  requestLeaveModalOpen: false,
  validated: false,
}

export const leavesSlice = createSlice({
  name: 'leaves',
  initialState,
  reducers: {
    reset: () => initialState,
    update: (state, action: PayloadAction<Leave[]>) => {
      state.myLeaves = action.payload
    },
    toggleRequestLeaveModal: (state, action: PayloadAction<boolean>) => {
      state.requestLeaveModalOpen = action.payload
      state.validated = false
    },
    setMyLeaves(state, action: PayloadAction<Leave[]>) {
      state.myLeaves = action.payload
    },
    setMyTeamLeaves(state, action: PayloadAction<LeaveManaged[]>) {
      state.myTeamLeaves = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase('leaves/getMyLeaves/pending', state => {
      state.loading = true
    })
    builder.addCase('leaves/getMyLeaves/fullfilled', state => {
      state.loading = false
    })
    builder.addCase('leaves/getMyLeaves/rejected', state => {
      state.loading = false
    })
    builder.addCase('leaves/postLeave/fulfilled', state => {
      state.validated = true
    })
  },
})

export const {
  reset, update, toggleRequestLeaveModal, setMyLeaves,
  setMyTeamLeaves,
} = leavesSlice.actions

export default leavesSlice.reducer
