import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Team } from './types'

const initialState = {
  team: undefined,
  teams: undefined,
}

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    reset: () => initialState,
    setTeam(state, action: PayloadAction<Team>) {
      state.team = action.payload
    },
    setTeams(state, action: PayloadAction<Team[]>) {
      state.teams = action.payload
    },
  },
})

export const {
  reset, setTeam, setTeams,
} = teamsSlice.actions

export default teamsSlice.reducer
