import { useAppSelector } from 'utils'
import { Member } from 'reducers/teams/types'
import { readRole } from 'utils/humanRead'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import IconButton from '@mui/material/IconButton'
import TableRow from '@mui/material/TableRow'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import 'dayjs/locale/fr'

type Props = {
  members: Member[]
  onDelete?: (string) => void
}

export default function TableTeam({ members, onDelete }: Props) {
  const { userInfo } = useAppSelector(state => state.userinfo)
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, marginTop: 2 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={200}>Nom</TableCell>
            <TableCell width={200}>Rôle</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members?.map(row => (
            <TableRow
              key={row.id}
            >
              <TableCell component="th" scope="row" style={{ width: 180, height: 53 }}>
                {row.firstName}
                {' '}
                {row.lastName}
              </TableCell>
              <TableCell style={{ width: 160 }} sx={{ textTransform: 'capitalize' }}>
                {readRole(row.role)}
              </TableCell>
              <TableCell align="right" sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                {userInfo.id !== row.id && (
                <IconButton aria-label="delete" onClick={() => onDelete(row.id)}>
                  <DeleteForeverIcon />
                </IconButton>
                )}

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const defaultProps = {
  onDelete: () => { /* to implement */ },
}

TableTeam.defaultProps = defaultProps
