/* eslint-disable import/prefer-default-export */
import {
  get, post, deleteRequest, patch,
} from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import { setMyLeaves, setMyTeamLeaves } from './leaves'
import { PostLeaveParams } from './types'

const getMyLeaves = () => createApiThunk(
  'leaves/getMyLeaves',
  () => get('/app-rh/absences/'),
  setMyLeaves,
)

const getMyTeamsLeaves = () => createApiThunk(
  'leaves/getMyTeamsLeaves',
  () => get('/app-rh/absences-management/'),
  setMyTeamLeaves,
)

const postLeave = (params: PostLeaveParams) => createApiThunk(
  'leaves/postLeave',
  () => post('/app-rh/absences/', params),
)

const deleteLeave = (leaveId: string) => createApiThunk(
  'leaves/deleteLeave',
  () => deleteRequest(`/app-rh/absences/${leaveId}/`),
)

const patchLeave = (leaveId: string, params) => createApiThunk(
  'leaves/patchLeave',
  () => patch(`/app-rh/absences/${leaveId}/`, params),
)

export {
  getMyLeaves,
  postLeave,
  deleteLeave,
  getMyTeamsLeaves,
  patchLeave,
}
