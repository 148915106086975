import { useAppDispatch, useAppSelector } from 'utils'
import { useEffect, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import { getMyTeamsLeaves, patchLeave } from 'reducers/leaves/leaves.thunk'
import { getUsersInfo, getValidatedByUsersInfo } from 'reducers/userInfo/userInfo.thunk'
import Box from '@mui/material/Box'
import Modal from 'components/modal'
import { ButtonStyle } from 'components/button'
import IconButton from '@mui/material/IconButton'
import Fade from '@mui/material/Fade'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TableLeaves from './table'

export default function LeavesManagement() {
  const dispatch = useAppDispatch()
  const { myTeamLeaves } = useAppSelector(state => state.leaves)
  const { usersInfo, usersValidatedByInfo } = useAppSelector(state => state.userinfo)
  const [openValidated, setOpenValidated] = useState(false)
  const [openDenied, setOpenDenied] = useState(false)
  const [openArchived, setOpenArchived] = useState(false)
  const [leaveId, setLeaveId] = useState<string>('')
  const [showDeniedModal, setShowDeniedModal] = useState(false)
  const [showGrantedModal, setShowGrantedModal] = useState(false)
  const [leaveList, setLeaveList] = useState([])
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    dispatch(getMyTeamsLeaves())
  }, [showDeniedModal, showGrantedModal])

  useEffect(() => {
    if (myTeamLeaves) {
      const usersIdList: string[] = myTeamLeaves.map(item => item.memberId)
      dispatch(getUsersInfo(usersIdList)).then(res => {
        if (res.type.includes('fulfilled')) {
          setChecked(true)
        }
      })
      const validatedByUsersIdList: string[] = myTeamLeaves
        .filter(item => item.lastModifiedBy && item.lastModifiedBy !== null)
        .map(item => item?.lastModifiedBy)
      dispatch(getValidatedByUsersInfo(validatedByUsersIdList)).then(res => {
        if (res.type.includes('fulfilled')) {
          setChecked(true)
        }
      })
    }
  }, [myTeamLeaves])

  useEffect(() => {
    const updatedLeavesList = myTeamLeaves?.map(item => {
      const matchingItem = usersInfo?.find(secondItem => secondItem.id === item.memberId)
      const matchingValidatedBy = usersValidatedByInfo?.find(thirdItem => thirdItem.id === item.lastModifiedBy)
      if (matchingItem) {
        return {
          ...item,
          lastName: matchingItem.lastName,
          fistName: matchingItem?.firstName,
          validatedByLastName: matchingValidatedBy?.lastName,
          validatedByFirstName: matchingValidatedBy?.firstName,
        }
      }
      return item
    })
    setLeaveList(updatedLeavesList)
  }, [usersInfo, usersValidatedByInfo])

  const getFilteredLeaves = (type: string) => {
    let filteredList = [...leaveList]
    switch (type) {
      case 'GRANTED':
        filteredList = filteredList.filter(obj => obj.status === 'GRANTED' && !obj.isArchived)
        return filteredList
      case 'PENDING':
        filteredList = filteredList.filter(obj => obj.status === 'PENDING' && !obj.isArchived)
        return filteredList
      case 'DENIED':
        filteredList = filteredList.filter(obj => obj.status === 'DENIED' && !obj.isArchived)
        return filteredList
      case 'ARCHIVED':
        filteredList = filteredList.filter(obj => obj.isArchived)
        return filteredList
      default:
        return []
    }
  }

  const handleDeniedLeave = (id: string) => {
    dispatch(patchLeave(id, { status: 'DENIED' })).then(res => {
      if (res.type.includes('fulfilled')) {
        setShowDeniedModal(false)
      }
    })
  }

  const handleGrantedLeave = (id: string) => {
    dispatch(patchLeave(id, { status: 'GRANTED' })).then(res => {
      if (res.type.includes('fulfilled')) {
        setShowGrantedModal(false)
      }
    })
  }

  const openDeniedModal = (id: string) => {
    setLeaveId(id)
    setShowDeniedModal(true)
  }

  const openGrantedModal = (id: string) => {
    setLeaveId(id)
    setShowGrantedModal(true)
  }

  return (
    <div className="page leaves-management">
      <div className="header d-flex flex-column">
        <div className="title">Gestion des absences</div>
      </div>
      <Fade in={checked}>
        <div className="wrapper mt-24">
          <div className="title">
            À valider
          </div>
          <TableLeaves
            type="PENDING"
            leaves={getFilteredLeaves('PENDING')}
            onGranted={openGrantedModal}
            onDenied={openDeniedModal}
          />
        </div>
      </Fade>
      <Fade in={checked}>
        <div className="wrapper mt-24">
          <div className="title">
            <Box display="flex" justifyContent="space-between">
              <div>
                Validées
              </div>
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenValidated(!openValidated)}
                >
                  {openValidated ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </Box>
          </div>
          <Collapse in={openValidated} timeout="auto" unmountOnExit>
            <TableLeaves type="GRANTED" leaves={getFilteredLeaves('GRANTED')} />
          </Collapse>
        </div>
      </Fade>
      <Fade in={checked}>
        <div className="wrapper mt-24">
          <div className="title">
            <Box display="flex" justifyContent="space-between">
              <div>
                Refusées
              </div>
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenDenied(!openDenied)}
                >
                  {openDenied ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </Box>
          </div>
          <Collapse in={openDenied} timeout="auto" unmountOnExit>
            <TableLeaves type="DENIED" leaves={getFilteredLeaves('DENIED')} />
          </Collapse>
        </div>
      </Fade>
      <Fade in={checked}>
        <div className="wrapper mt-24">
          <div className="title">
            <Box display="flex" justifyContent="space-between">
              <div>
                Archivées
              </div>
              <div>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpenArchived(!openArchived)}
                >
                  {openArchived ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </Box>
          </div>
          <Collapse in={openArchived} timeout="auto" unmountOnExit>
            <TableLeaves type="ARCHIVED" leaves={getFilteredLeaves('ARCHIVED')} />
          </Collapse>
        </div>
      </Fade>
      <Modal
        title="Décliner la demande d’absence"
        bodyText="Êtes-vous sûr de vouloir décliner la demande d’absence ?"
        handleClose={() => setShowDeniedModal(false)}
        onButtonClick={() => handleDeniedLeave(leaveId)}
        buttonStyle={ButtonStyle.primary}
        open={showDeniedModal}
        buttonText="Oui, décliner"
      />
      <Modal
        title="Accepter la demande d’absence"
        bodyText="Êtes-vous sûr de vouloir accepter la demande d’absence ?"
        handleClose={() => setShowGrantedModal(false)}
        onButtonClick={() => handleGrantedLeave(leaveId)}
        buttonStyle={ButtonStyle.primary}
        open={showGrantedModal}
        buttonText="Oui, accepter"
      />
    </div>
  )
}
