import terms from 'assets/terms'

export interface NavTabElement {
  icon?: string,
  title: string,
  path: string,
  role: string[],
}

const navTabElements: NavTabElement[] = [
  {
    title: terms.Routes.agenda.tabTitle,
    path: terms.Routes.agenda.path,
    role: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    title: terms.Routes.leaves.tabTitle,
    path: terms.Routes.leaves.path,
    role: ['USER', 'MANAGER', 'ADMIN'],
  },
  {
    title: terms.Routes.leavesManagement.tabTitle,
    path: terms.Routes.leavesManagement.path,
    role: ['MANAGER', 'ADMIN'],
  },
  {
    title: terms.Routes.myTeam.tabTitle,
    path: terms.Routes.myTeam.path,
    role: ['MANAGER'],
  },
  {
    title: terms.Routes.teams.tabTitle,
    path: terms.Routes.teams.path,
    role: ['ADMIN'],
  },
  {
    title: terms.Routes.profile.tabTitle,
    path: terms.Routes.profile.path,
    role: ['USER', 'MANAGER', 'ADMIN'],
  },
]

export default navTabElements
