import { ReactNode } from 'react'
import dgexLogo from 'assets/logos/dgex.svg'
import pdiLogo from 'assets/logos/pdi.svg'
import AccountMenu from './menu'
import './style.scss'

type Props = {
  innerComponent?: ReactNode;
  hasAccess: boolean;
}

const defaultProps = {
  innerComponent: <div />,
}

export default function TopBar({ innerComponent, hasAccess }: Props) {
  return (
    <header>
      <div className="logos">
        <a className="flex-center" href="/">
          <img alt="dgex" src={dgexLogo} />
          <img className="pdi" src={pdiLogo} alt="PDI" />
        </a>
      </div>
      {hasAccess && innerComponent}
      <AccountMenu />
    </header>
  )
}

TopBar.defaultProps = defaultProps
