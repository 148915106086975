/* eslint-disable max-len */
import { useCallback, useState, useEffect } from 'react'
import {
  Grid, Paper, Typography,
  MenuItem, FormControl,
} from '@mui/material'
import { Absence } from 'reducers/agenda/types'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useAppDispatch, useAppSelector } from 'utils'
import { getAgenda, getTeams } from 'reducers/agenda/agenda.thunk'
import { getUsersInfo } from 'reducers/userInfo/userInfo.thunk'
import './style.scss'
import dayjs from 'dayjs'
import Fade from '@mui/material/Fade'
import isBetween from 'dayjs/plugin/isBetween'
import 'dayjs/locale/fr'
import terms from 'assets/terms'
import Header from './header'
import AgendaTable from './agendaTable'

dayjs.locale('fr')
dayjs.extend(isBetween)

export default function Agenda() {
  const dispatch = useAppDispatch()
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [teamNameFilter, setTeamNameFilter] = useState<string>('')
  const [view, setView] = useState('month')
  const { usersInfo, userInfo } = useAppSelector(state => state.userinfo)
  const { agenda, teams } = useAppSelector(state => state.agenda)
  const [teamMembers, setTeamMembers] = useState([])
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (teams && userInfo?.teamId) {
      setTeamNameFilter(userInfo.teamId)
    } else if (teams && !userInfo?.teamId) {
      setTeamNameFilter(teams[0].id)
    }
  }, [teams, userInfo])

  useEffect(() => {
    dispatch(getTeams())
  }, [])

  useEffect(() => {
    if (teamNameFilter !== '' && teamNameFilter !== 'all') {
      dispatch(getAgenda(teamNameFilter)).then(res => {
        if (res.type.includes('fulfilled')) {
          setChecked(true)
        }
      })
    }
    if (teamNameFilter === 'all') {
      dispatch(getAgenda('')).then(res => {
        if (res.type.includes('fulfilled')) {
          setChecked(true)
        }
      })
    }
  }, [teamNameFilter])

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleChangeView = () => {
    setView(prevView => (prevView === 'month' ? 'week' : 'month'))
  }

  const handleTeamNameFilterChange = (event: SelectChangeEvent) => {
    setTeamNameFilter(event.target.value)
  }

  const handleChangeMonth = (direction: 'prev' | 'next') => {
    setCurrentDate(current => (direction === 'prev' ? current.subtract(1, 'month') : current.add(1, 'month')))
  }

  const isFirstDayOfAbsence = (day: dayjs.Dayjs, absence: Absence) => dayjs(day).isSame(dayjs(absence.startDate), 'day')

  const getAbsenceOnDay = (day, absences) => {
    const dayAbsences = absences.filter(absence => dayjs(day).isBetween(dayjs(absence.startDate), dayjs(absence.endDate), 'day', '[]')
      && absence.status !== 'DENIED')

    return dayAbsences
  }

  const calculateAbsenceDays = useCallback(
    (startDate: string, endDate: string) => dayjs(endDate).diff(dayjs(startDate), 'day') + 1,
    [],
  )

  useEffect(() => {
    if (agenda) {
      const usersIdList: string[] = agenda.map(item => item.id)
      dispatch(getUsersInfo(usersIdList))
    }
  }, [agenda])

  useEffect(() => {
    const updatedMyTeamList = agenda?.map(item => {
      const matchingItem = usersInfo?.find(secondItem => secondItem.id === item.id)
      if (matchingItem) {
        return { ...item, lastName: matchingItem.lastName, firstName: matchingItem.firstName }
      }
      return item
    })
    setTeamMembers(updatedMyTeamList)
  }, [usersInfo, agenda])

  const monthYear = capitalizeFirstLetter(currentDate.format('MMMM YYYY'))
  const daysOfWeek = ['D', 'L', 'M', 'M', 'J', 'V', 'S']
  const daysInMonth = currentDate.daysInMonth()

  return (
    <div className="page agenda" style={{ width: 1256 }}>
      <div className="wrapper mt-24">
        <Grid container direction="column" className="agenda-grid-container">
          <Grid item className="header">
            <Paper className="paper">
              <Header
                monthYear={monthYear}
                view={view}
                handleChangeView={handleChangeView}
                handleChangeMonth={handleChangeMonth}
              />
              <Grid
                container
                spacing={2}
                alignItems="center"
                className="filter-and-calendar-container"
                flexWrap="nowrap"
              >
                <Grid item xs={2} className="team">
                  <FormControl>
                    <Select
                      className="select"
                      value={teamNameFilter}
                      onChange={handleTeamNameFilterChange}
                      displayEmpty
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value="all">
                        <em>Tout le PDI</em>
                      </MenuItem>
                      {teams && teams.map(team => (
                        <MenuItem key={team.id} value={team.id}>
                          <em>{team.name || terms.Page.agenda.none}</em>
                        </MenuItem>
                      ))}

                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={10} container className="calendar-grid-container">
                  <Grid container spacing={1} className="days-of-month-container">
                    {Array.from({ length: daysInMonth }, (_, i) => {
                      const day = currentDate.startOf('month').add(i, 'day')
                      const dayOfWeekIndex = day.day() % 7
                      const isWeekend = dayOfWeekIndex === 0 || dayOfWeekIndex === 6
                      return (
                        <Grid item key={`${i}-${day.format('YYYY-MM-DD')}`} className={`day ${isWeekend ? 'weekend' : ''}`}>
                          <Typography className="day-of-week">
                            {daysOfWeek[dayOfWeekIndex]}
                          </Typography>
                          <Typography className="date-number">
                            {day.date().toString().padStart(2, '0')}
                          </Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Fade in={checked}>
            <div>
              {teamMembers && teamMembers.map(a => (
                <AgendaTable
                  key={a.id}
                  user={a}
                  daysInMonth={daysInMonth}
                  currentDate={currentDate}
                  getAbsenceOnDay={d => getAbsenceOnDay(d, a.absences)}
                  calculateAbsenceDays={calculateAbsenceDays}
                  isFirstDayOfAbsence={isFirstDayOfAbsence}
                />
              ))}
            </div>
          </Fade>

        </Grid>
      </div>
    </div>
  )
}
