import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage
import { auth } from '@osrdata/app_core'
import appReducer from './app'
import leavesReducer from './leaves/leaves'
import userInfoReducer from './userInfo/userInfo'
import agendaReducer from './agenda/agenda'
import teamsReducer from './teams/teams'

const userReducer = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user', 'leaves'],
}

export const rootReducer = {
  user: userReducer,
  app: appReducer,
  leaves: leavesReducer,
  userinfo: userInfoReducer,
  agenda: agendaReducer,
  teams: teamsReducer,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
