import dayjs from 'dayjs'
import { Absence, Agenda } from './types'

const separateAbsencesByMonth = (data: Agenda[]): Agenda[] => {
  const newData: Agenda[] = []

  data.forEach(agenda => {
    const newAgenda: Agenda = {
      id: agenda.id,
      role: agenda.role,
      teamId: agenda.teamId,
      teamManagerId: agenda.teamManagerId,
      teamName: agenda.teamName,
      absences: [],
    }

    agenda.absences.forEach((absence: Absence) => {
      const startDate = dayjs(absence.startDate)
      const endDate = dayjs(absence.endDate)

      let currentStart = startDate.startOf('day')
      while (currentStart.isBefore(endDate) || currentStart.isSame(endDate, 'day')) {
        const endOfMonth = currentStart.endOf('month')
        const startOfNextMonth = endOfMonth.add(1, 'day').startOf('day')

        const currentEnd = endDate.isBefore(endOfMonth) ? endDate : endOfMonth
        let { startTimeOfDay } = absence
        let { endTimeOfDay } = absence

        if (absence.endTimeOfDay === 'MORNING'
    && absence.startTimeOfDay === 'AFTERNOON'
    && endDate.isSame(startOfNextMonth, 'day')) {
          endTimeOfDay = 'FULL'
        } else if (currentEnd.isSame(endOfMonth, 'day') && !currentEnd.isSame(endDate, 'day')) {
          endTimeOfDay = 'FULL'
        } else if (currentEnd.isSame(endDate, 'day') && absence.endTimeOfDay === 'MORNING') {
          endTimeOfDay = 'MORNING'
        } else {
          startTimeOfDay = 'FULL'
          endTimeOfDay = 'FULL'
        }

        if (currentStart.isSame(startDate, 'day') && absence.startTimeOfDay === 'AFTERNOON') {
          startTimeOfDay = 'AFTERNOON'
        } else {
          startTimeOfDay = 'FULL'
        }

        newAgenda.absences.push({
          ...absence,
          startDate: currentStart.format('YYYY-MM-DD'),
          endDate: currentEnd.format('YYYY-MM-DD'),
          startTimeOfDay,
          endTimeOfDay,
          startMonth: currentStart.isSame(startDate, 'month'),
        })

        currentStart = endOfMonth.add(1, 'day').startOf('month')
      }
    })
    newData.push(newAgenda)
  })

  return newData
}

export const agendaService = {
  separateAbsencesByMonth,
}
