import {
  Typography, IconButton, Box,
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface HeaderProps {
  monthYear: string;
  view: string;
  handleChangeView: () => void;
  handleChangeMonth: (direction: 'prev' | 'next') => void;
}

function Header({
  monthYear, view, handleChangeView, handleChangeMonth,
}: HeaderProps) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={3}>
      <Typography sx={{ fontWeight: 800 }} variant="h5">{monthYear}</Typography>
      <div>
        <IconButton
          onClick={() => handleChangeMonth('prev')}
        >
          <ChevronLeftIcon sx={{ color: 'black' }} />
        </IconButton>
        <IconButton
          onClick={() => handleChangeMonth('next')}
        >
          <ChevronRightIcon sx={{ color: 'black' }} />
        </IconButton>
      </div>

    </Box>

  )
}

export default Header
