import { createApiThunk } from 'utils'
import { get } from '@osrdata/app_core/dist/requests'
import { setAgenda, setTeams } from './agenda'

const getAgenda = (teamId: string) => createApiThunk(
  'agenda/getAgenda',
  () => get('/app-rh/agenda/', { teamId }),
  setAgenda,
)

const getTeams = () => createApiThunk(
  'agenda/getAgenda',
  () => get('/app-rh/teams/'),
  setTeams,
)

export {
  getAgenda,
  getTeams,
}
