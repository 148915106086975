import { ReactElement } from 'react'
import { useAppSelector } from 'utils/hooks'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { RootState } from 'reducers/store'
import { NavTabElement } from 'utils/navTabElements'

import './style.scss'

type Props = {
  element: NavTabElement;
}

export default function TabElement({ element }: Props): ReactElement {
  const {
    icon,
    title,
    path,
    role,
  } = element
  const { userInfo } = useAppSelector(state => state.userinfo)

  const location = useLocation()

  const isSelected = location.pathname.includes(path)

  const { appPermissions } = useSelector((state: RootState) => state.user)
  const isAllowed = role.includes(userInfo?.role)
  || ((appPermissions as string[]).some(permission => role.includes(permission)))

  return (
    <div>
      {isAllowed
      && (
        <Link to={path}>
          <div className={`tab-element ${isSelected ? 'selected' : ''}`}>
            {icon && (
              <div className="mr-2">
                <img src={icon} alt="tab icon" />
              </div>
            )}
            <div className="title">
              {title}
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}
