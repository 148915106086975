import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { useEffect } from 'react'
import { getManagerInfo } from 'reducers/userInfo/userInfo.thunk'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Box, Paper } from '@mui/material'
import { readRole } from 'utils/humanRead'
import Input from './input'

export default function Profile() {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user)
  const { userInfo, managerInfo } = useAppSelector(state => state.userinfo)

  useEffect(() => {
    if (userInfo && userInfo.teamManagerId) {
      dispatch(getManagerInfo(userInfo.teamManagerId))
    }
  }, [userInfo])

  return (
    <div className="page profile">
      <div className="header d-flex flex-column">
        <div className="title">Mon profil</div>
      </div>
      <div className="wrapper mt-24">
        <Box display="flex" flexDirection="row" alignItems="center">
          <AccountCircleIcon sx={{ fontSize: 150 }} style={{ color: 'grey' }} />
          <Paper elevation={0}>
            <Typography
              sx={{ fontSize: 34, fontWeight: 800 }}
            >
              {`${user.account.firstName} ${user.account.lastName}`}
            </Typography>
            <div style={{ textTransform: 'capitalize' }}>
              Rôle :
              {' '}
              {readRole(userInfo?.role)}
            </div>
          </Paper>
        </Box>

        <div className="title mt-24">Informations</div>
        <Grid container spacing={5} columns={11} mb={3}>
          <Grid item xs={3}>
            <Input label="Nom" value={user.account.lastName} endAdornment={false} disabled />
          </Grid>
          <Grid item xs={3}>
            <Input label="Prénom" value={user.account.firstName} endAdornment={false} disabled />
          </Grid>
          <Grid item xs={3}>
            <Input label="Mail" value={user.account.email} endAdornment={false} disabled />
          </Grid>
          {userInfo?.teamManagerId && (
            <>
              <Grid item xs={3}>
                <Input
                  label="Nom du Manager"
                  value={managerInfo ? managerInfo[0]?.lastName : ''}
                  endAdornment
                  disabled
                  role={userInfo?.role}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  label="Prénom du Manager"
                  value={managerInfo ? managerInfo[0]?.firstName : ''}
                  endAdornment
                  disabled
                  role={userInfo?.role}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  label="Équipe"
                  value={userInfo?.teamName}
                  endAdornment
                  disabled
                  role={userInfo?.role}
                />
              </Grid>
            </>
          )}

        </Grid>
      </div>
    </div>
  )
}
