import { styled } from '@mui/material/styles'
import SimpleButton, { ButtonStyle } from 'components/button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const LeavesDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    paddingTop: 0,
  },
  '& .MuiDialogActions-root': {},
}))

type Props = {
  title: string
  bodyText?: string
  handleClose: () => void
  onButtonClick: () => void
  buttonStyle: ButtonStyle
  buttonText: string
  open: boolean
  startIcon?: JSX.Element;
}

export default function Modal({
  title, bodyText, handleClose, onButtonClick, buttonStyle, open,
  buttonText, startIcon,
}: Props) {
  return (
    <div>
      <LeavesDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-leaves"
        className="modal-leaves"
        PaperProps={{
          sx: {
            borderRadius: '20px',
            boxShadow: 'none',
            padding: '15px 20px',
            minWidth: 500,
          },
        }}
      >
        <DialogTitle sx={{ fontSize: 20, fontWeight: 800 }} id="modal-leaves">
          {title}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 20,
            top: 25,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
            {bodyText}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Link href="#" onClick={() => handleClose()} mr={2} sx={{ color: '#4d32e2' }}>Annuler</Link>
          <SimpleButton
            text={buttonText}
            onClick={onButtonClick}
            sx={{ marginRight: '1rem' }}
            startIcon={startIcon}
            style={buttonStyle}
          />
        </DialogActions>
      </LeavesDialog>
    </div>
  )
}

const defaultProps = {
  bodyText: '',
  startIcon: '',
}

Modal.defaultProps = defaultProps
