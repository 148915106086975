import { ReactElement } from 'react'
import { NavTabElement } from 'utils/navTabElements'
import TabElement from './tabElement'
import './style.scss'

type Props = {
  tabs: NavTabElement[]
}

export default function TabList({ tabs }: Props): ReactElement {
  return (
    <nav className="tab-list">
      {tabs.map(el => (
        <TabElement key={el.title} element={el} />
      ))}
    </nav>
  )
}
