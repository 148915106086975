import { LeaveManaged } from 'reducers/leaves/types'
import SimpleButton, { ButtonStyle } from 'components/button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { readRole } from 'utils/humanRead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/Check'
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/fr'

type Props = {
  leaves: LeaveManaged[]
  type: string
  onGranted?: (string) => void
  onDenied?: (string) => void
}

const statusChip = (status: string) => {
  switch (status) {
    case 'DENIED':
      return (
        <Chip
          color="warning"
          style={{ backgroundColor: '#f2caca', borderRadius: 6, color: '#BD2929' }}
          icon={<CloseIcon style={{ color: '#BD2929' }} />}
          label="Déclinée"
        />
      )
    case 'GRANTED':
      return (
        <Chip
          color="warning"
          style={{ backgroundColor: '#d7e7d3', borderRadius: 6, color: '#2c6c26' }}
          icon={<CheckIcon style={{ color: '#2c6c26' }} />}
          label="Acceptée"
        />
      )
    default:
      return <div />
  }
}

const period = row => {
  if ((row.startDate === row.endDate) && (row.startTimeOfDay === row.endTimeOfDay)) {
    return (
      <div>
        {dayjs(row.startDate).locale('fr').format('DD MMMM')}
        {' '}
        -
        {' '}
        {row.startTimeOfDay === 'MORNING' ? 'matin' : 'après-midi'}
      </div>
    )
  }
  return (
    <div style={{ lineHeight: '0.8' }}>
      {dayjs(row.startDate).locale('fr').format('DD MMMM')}
      {' '}
      {row.startTimeOfDay === 'MORNING' ? 'matin' : 'après-midi'}
      <br />
      {' '}
      <span style={{ marginLeft: '40px' }}>-</span>
      {' '}
      <br />
      {dayjs(row.endDate).locale('fr').format('DD MMMM')}
      {' '}
      {row.endTimeOfDay === 'AFTERNOON' ? 'après-midi' : 'matin'}
    </div>
  )
}

const archivedChipLabel = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'En attente'
    case 'DENIED':
      return 'Déclinée'
    case 'GRANTED':
      return 'Acceptée'
    default:
      return ''
  }
}

export default function TableLeaves({
  leaves, type, onGranted, onDenied,
}: Props) {
  dayjs.extend(relativeTime)
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, marginTop: 2 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={200}>Dates</TableCell>
            <TableCell width={140}>Type d’absence</TableCell>
            <TableCell width={160}>Demandeur</TableCell>
            {type === 'PENDING' && (
              <TableCell>Rôle</TableCell>
            )}
            {type === 'PENDING' && (
            <TableCell>Demande créée</TableCell>
            )}
            {type !== 'PENDING' && (
              <TableCell width={130}>Statut</TableCell>
            )}
            {type !== 'PENDING' && type !== 'ARCHIVED' && (
              <TableCell>{type === 'GRANTED' ? 'Validée par' : 'Refusée par'}</TableCell>
            )}
            {type === 'ARCHIVED' && (
              <TableCell>Traitée par</TableCell>
            )}

          </TableRow>
        </TableHead>
        <TableBody>
          {leaves.map(row => (
            <TableRow
              key={row.id}
            >
              <TableCell component="th" scope="row">
                {period(row)}
              </TableCell>
              <TableCell>
                {row.isArchived ? (
                  <Chip
                    color="primary"
                    style={{ backgroundColor: '#DADADA', borderRadius: 6, color: '#797979' }}
                    icon={<BeachAccessOutlinedIcon style={{ color: '#797979' }} />}
                    label="Congés"
                  />
                ) : (
                  <Chip
                    color="primary"
                    style={{ backgroundColor: '#cae9f2', borderRadius: 6, color: '#217896' }}
                    icon={<BeachAccessOutlinedIcon style={{ color: '#217896' }} />}
                    label="Congés"
                  />
                )}
              </TableCell>
              <TableCell>
                {row.fistName}
                {' '}
                {row.lastName}
              </TableCell>
              {type === 'PENDING' && (
                <TableCell sx={{ textTransform: 'capitalize' }}>{readRole(row.memberRole)}</TableCell>
              )}
              {type === 'PENDING' && (
                <TableCell>{dayjs(row.createdAt).locale('fr').fromNow()}</TableCell>
              )}
              {type === 'PENDING' && (
              <TableCell align="right" sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                <Box display="flex" justifyContent="flex-end" gap={2}>
                  <SimpleButton
                    text="Accepter"
                    onClick={() => onGranted(row.id)}
                    startIcon={<CheckIcon />}
                    sx={{ height: '32px' }}
                  />
                  <SimpleButton
                    text="Décliner"
                    onClick={() => onDenied(row.id)}
                    startIcon={<CloseIcon />}
                    sx={{ height: '32px' }}
                    style={ButtonStyle.light}
                  />
                </Box>
              </TableCell>
              )}
              <TableCell>
                {!row.isArchived ? statusChip(row.status) : (
                  <Chip
                    color="primary"
                    style={{ backgroundColor: '#DADADA', borderRadius: 6, color: '#797979' }}
                    icon={<BeachAccessOutlinedIcon style={{ color: '#797979' }} />}
                    label={archivedChipLabel(row.status)}
                  />
                )}
              </TableCell>
              {type !== 'PENDING' && (
                <TableCell>
                  {row.lastModifiedBy ? (
                    <div>
                      {row.validatedByFirstName}
                      {' '}
                      {row.validatedByLastName}
                    </div>
                  ) : 'Non renseigné'}

                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const defaultProps = {
  onGranted: () => { /* to implement */ },
  onDenied: () => { /* to implement */ },
}

TableLeaves.defaultProps = defaultProps
