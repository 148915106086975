import { useAppDispatch, useAppSelector } from 'utils'
import { useEffect, useState } from 'react'
import SimpleButton, { ButtonStyle } from 'components/button'
import { Team } from 'reducers/teams/types'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Modal from 'components/modal'
import ModalSearch from 'components/modalSearch'
import IconButton from '@mui/material/IconButton'
import Fade from '@mui/material/Fade'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  getTeam, getTeams, removeMember, addMember,
} from 'reducers/teams/teams.thunk'
import { getUsersInfo } from 'reducers/userInfo/userInfo.thunk'
import AddIcon from '@mui/icons-material/Add'
import TableTeam from './table'

export default function Teams() {
  const dispatch = useAppDispatch()
  const { usersInfo } = useAppSelector(state => state.userinfo)
  const { teams, team } = useAppSelector(state => state.teams)
  const [openCollapse, setOpenCollapse] = useState('')
  const [teamMembers, setTeamMembers] = useState([])
  const [memberId, setMemberId] = useState<string>('')
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team>(undefined)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    dispatch(getTeams()).then(res => {
      if (res.type.includes('fulfilled')) {
        setChecked(true)
      }
    })
  }, [])

  const handleExpend = (t: Team) => {
    setSelectedTeam(t)
    if (t.id !== openCollapse) {
      dispatch(getTeam(t.id)).then(res => {
        if (res.type.includes('fulfilled')) {
          setOpenCollapse(t.id)
        }
      })
    } else {
      setOpenCollapse('')
    }
  }

  useEffect(() => {
    if (team) {
      const usersIdList: string[] = team?.members.map(item => item.id)
      dispatch(getUsersInfo(usersIdList))
    }
  }, [team])

  useEffect(() => {
    const updatedMyTeamList = team?.members?.map(item => {
      const matchingItem = usersInfo?.find(secondItem => secondItem.id === item.id)

      if (matchingItem) {
        return { ...item, lastName: matchingItem.lastName, firstName: matchingItem.firstName }
      }
      return item
    })
    setTeamMembers(updatedMyTeamList)
  }, [usersInfo])

  const handleRemoveMember = (id: string) => {
    dispatch(removeMember(id)).then(res => {
      if (res.type.includes('fulfilled')) {
        setShowRemoveModal(false)
        dispatch(getTeam(team.id))
      }
    })
  }

  const handleAddMember = () => {
    dispatch(addMember(memberId, selectedTeam.id)).then(res => {
      if (res.type.includes('fulfilled')) {
        setShowSearchModal(false)
        dispatch(getTeam(selectedTeam.id))
      }
    })
  }

  const openSearchModal = (t: Team) => {
    setSelectedTeam(t)
    setShowSearchModal(true)
  }

  const openRemoveModal = (id: string) => {
    setMemberId(id)
    setShowRemoveModal(true)
  }

  const handleSelect = (e, value) => {
    setMemberId(value.id)
  }

  return (
    <div className="page teams">
      <div className="header d-flex flex-column">
        <div className="title">Les équipes</div>
        <Fade in={checked}>
          <div>
            {teams?.map((t: Team) => (
              <div key={t.id} className="wrapper mt-24">
                <div className="title">
                  <Box display="flex" justifyContent="space-between">
                    <div>
                      {t.name}
                    </div>
                    <div style={{ marginLeft: 'auto', marginRight: 20 }}>
                      <SimpleButton
                        text="Ajouter un membre"
                        startIcon={<AddIcon />}
                        sx={{ height: '32px' }}
                        onClick={() => openSearchModal(t)}
                        style={ButtonStyle.light}
                      />
                    </div>
                    <div>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleExpend(t)}
                      >
                        {openCollapse === t.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </div>
                  </Box>
                </div>
                <Collapse in={openCollapse === t.id} timeout="auto" unmountOnExit>
                  <TableTeam members={teamMembers} onDelete={openRemoveModal} />
                </Collapse>
              </div>
            ))}
          </div>
        </Fade>
        <Modal
          title="Suppression du membre"
          bodyText={`Êtes-vous sûr de vouloir supprimer ce membre de l'équipe ${team?.name} ?`}
          handleClose={() => setShowRemoveModal(false)}
          onButtonClick={() => handleRemoveMember(memberId)}
          buttonStyle={ButtonStyle.error}
          open={showRemoveModal}
          buttonText="Oui, supprimer"
        />
        <ModalSearch
          title={`Ajout d'un membre à l'équipe ${selectedTeam?.name} ?`}
          handleClose={() => setShowSearchModal(false)}
          onButtonClick={() => handleAddMember()}
          buttonStyle={ButtonStyle.primary}
          open={showSearchModal}
          buttonText="Ajouter le membre"
          handleSelect={(e, value) => handleSelect(e, value)}
        />
      </div>
    </div>
  )
}
