export const readRole = (role: string) => {
  switch (role) {
    case 'USER':
      return 'collaborateur'
    case 'MANAGER':
      return 'manager'
    case 'ADMIN':
      return 'administrateur'
    default:
      return ''
  }
}
