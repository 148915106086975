/* eslint-disable max-len */
export const terms = {
  Routes: {
    agenda: {
      title: 'Agenda',
      path: '/agenda',
      tabTitle: 'Agenda',
    },
    leaves: {
      title: 'Mes absences',
      path: '/absences',
      tabTitle: 'Mes absences',
    },
    leavesManagement: {
      title: 'Gestion des absences',
      path: '/gestion-absences',
      tabTitle: 'Gestion des absences',
    },
    profile: {
      title: 'Profile',
      path: '/profile',
      tabTitle: 'Mon profil',
    },
    settings: {
      title: 'Paramètrages',
      path: '/parametrages',
      tabTitle: 'Paramètrages',
    },
    myTeam: {
      title: 'Mon équipe',
      path: '/mon-equipe',
      tabTitle: 'Mon équipe',
    },
    teams: {
      title: 'Les équipes',
      path: '/les-equipes',
      tabTitle: 'Les équipes',
    },
  },
  Common: {
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    copyLink: 'Copier le lien',
    linkCopied: 'Lien copié dans le presse-papier',
    invit: 'Inviter',
    READ_ONLY: 'Lecture',
    READ_WRITE: 'Écriture',
  },
  Page: {
    leaves: {
      title: 'Mes demandes d’absence',
      subtitle1: 'L’outil ne permet de gérer que les congés, pas les congés spéciaux (maladie, maternité, grève...)',
      subtitle2: 'Si vous souhaitez modifier une demande, supprimez-là et créez-en une nouvelle.',
      validated: 'Validées',
      inProcess: 'En cours',
      archived: 'Archivées',
      denied: 'Refusées',
    },
    agenda: {
      unspecified: 'Non spécifié',
      absentClass: 'absent',
      presentClass: '',
      none: 'Aucune',
      leavesLabel: 'Congés',
      month: 'Mois',
      workWeek: 'Semaine de travail',
    },
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      serverDown: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
} as const

export default terms
