import { Leave } from 'reducers/leaves/types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import BeachAccessOutlinedIcon from '@mui/icons-material/BeachAccessOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

type Props = {
  leaves: Leave[]
  type: string
  onDelete?: (string) => void
}

const statusChip = (status: string) => {
  switch (status) {
    case 'PENDING':
      return (
        <Chip
          style={{ backgroundColor: '#f1dfc6', borderRadius: 6, color: '#bd5601' }}
          icon={<AccessTimeOutlinedIcon style={{ color: '#bd5601' }} />}
          label="En attente"
        />
      )
    case 'DENIED':
      return (
        <Chip
          style={{ backgroundColor: '#f2caca', borderRadius: 6, color: '#BD2929' }}
          icon={<CloseIcon style={{ color: '#BD2929' }} />}
          label="Déclinée"
        />
      )
    case 'GRANTED':
      return (
        <Chip
          style={{ backgroundColor: '#d7e7d3', borderRadius: 6, color: '#2c6c26' }}
          icon={<CheckIcon style={{ color: '#2c6c26' }} />}
          label="Acceptée"
        />
      )
    default:
      return <div />
  }
}

const archivedChipLabel = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'En attente'
    case 'DENIED':
      return 'Déclinée'
    case 'GRANTED':
      return 'Acceptée'
    default:
      return ''
  }
}

const period = row => {
  if ((row.startDate === row.endDate) && (row.startTimeOfDay === row.endTimeOfDay)) {
    return (
      <div>
        {dayjs(row.startDate).locale('fr').format('DD MMMM')}
        {' '}
        -
        {' '}
        {row.startTimeOfDay === 'MORNING' ? 'matin' : 'après-midi'}
      </div>
    )
  }
  return (
    <div style={{ lineHeight: '0.8' }}>
      {dayjs(row.startDate).locale('fr').format('DD MMMM')}
      {' '}
      {row.startTimeOfDay === 'MORNING' ? 'matin' : 'après-midi'}
      <br />
      {' '}
      <span style={{ marginLeft: '40px' }}>-</span>
      {' '}
      <br />
      {dayjs(row.endDate).locale('fr').format('DD MMMM')}
      {' '}
      {row.endTimeOfDay === 'AFTERNOON' ? 'après-midi' : 'matin'}
    </div>
  )
}

export default function TableLeaves({ leaves, onDelete, type }: Props) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, marginTop: 2 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width={230}>Dates</TableCell>
            <TableCell width={180}>Type d’absence</TableCell>
            <TableCell width={200}>Statut</TableCell>
            {type !== 'PENDING' && type !== 'ARCHIVED' && (
              <TableCell>{type === 'GRANTED' ? 'Validée par' : 'Refusée par'}</TableCell>
            )}
            {type === 'ARCHIVED' && (
              <TableCell>Traitée par</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {leaves.map(row => (
            <TableRow
              key={row.id}
            >
              <TableCell component="th" scope="row" style={{ width: 220 }}>
                {period(row)}
              </TableCell>
              <TableCell style={{ width: 160 }}>
                {row.isArchived ? (
                  <Chip
                    color="primary"
                    style={{ backgroundColor: '#DADADA', borderRadius: 6, color: '#797979' }}
                    icon={<BeachAccessOutlinedIcon style={{ color: '#797979' }} />}
                    label="Congés"
                  />
                ) : (
                  <Chip
                    color="primary"
                    style={{ backgroundColor: '#cae9f2', borderRadius: 6, color: '#217896' }}
                    icon={<BeachAccessOutlinedIcon style={{ color: '#217896' }} />}
                    label="Congés"
                  />
                )}

              </TableCell>
              <TableCell>
                {!row.isArchived ? statusChip(row.status) : (
                  <Chip
                    color="primary"
                    style={{ backgroundColor: '#DADADA', borderRadius: 6, color: '#797979' }}
                    icon={<BeachAccessOutlinedIcon style={{ color: '#797979' }} />}
                    label={archivedChipLabel(row.status)}
                  />
                )}
              </TableCell>
              {type !== 'PENDING' && (
                <TableCell>
                  {row.lastModifiedBy ? (
                    <div>
                      {row.validatedByFirstName}
                      {' '}
                      {row.validatedByLastName}
                    </div>
                  ) : 'Non renseigné'}

                </TableCell>
              )}
              {row.status !== 'DENIED' && !row.isArchived && (
                <TableCell align="right" sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                  <IconButton aria-label="delete" onClick={() => onDelete(row.id)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const defaultProps = {
  onDelete: () => { /* to implement */ },
}

TableLeaves.defaultProps = defaultProps
