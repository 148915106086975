/* eslint-disable import/prefer-default-export */
import { get, patch } from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import { setTeam, setTeams } from './teams'

const getTeam = (teamId: string) => createApiThunk(
  'teams/getTeam',
  () => get(`/app-rh/teams/${teamId}/`),
  setTeam,
)

const removeMember = (memberId: string) => createApiThunk(
  'teams/patchMember',
  () => patch(`/app-rh/members/${memberId}/`, { teamId: null }),
)

const addMember = (memberId: string, teamId: string) => createApiThunk(
  'teams/addMember',
  () => patch(`/app-rh/members/${memberId}/`, { teamId }),
)

const getTeams = () => createApiThunk(
  'teams/getTeams',
  () => get('/app-rh/teams/'),
  setTeams,
)

export {
  getTeam,
  removeMember,
  getTeams,
  addMember,
}
