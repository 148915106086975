import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Typography from '@mui/material/Typography'
import dayjs, { Dayjs } from 'dayjs'
import { DateValidationError } from '@mui/x-date-pickers/models'
import { bankHollidays } from './bankHolidays'
import 'dayjs/locale/fr'

type Props = {
  setDate: React.Dispatch<React.SetStateAction<Dayjs | null>>,
  date: Dayjs | null,
  label: string
  minDate: any
  setError: React.Dispatch<React.SetStateAction<DateValidationError | null>>,
}

export default function DatePickerComponent({
  setDate, date, label, minDate, setError,
}: Props) {
  const isWeekend = (we: Dayjs) => {
    const day = we.day()
    return day === 0 || day === 6 || bankHollidays.includes(dayjs(we).locale('fr').format('YYYY-MM-DD'))
  }
  return (
    <div style={{ width: '100%' }}>
      <Typography gutterBottom sx={{ fontSize: 14 }} mb={1}>
        {label}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DatePicker
          value={date}
          slotProps={{ popper: { placement: 'right' } }}
          disablePast
          onChange={newValue => setDate(newValue)}
          minDate={minDate}
          onError={newError => setError(newError)}
          shouldDisableDate={isWeekend}
        />
      </LocalizationProvider>
    </div>
  )
}
