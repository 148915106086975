import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userInfo, managerInfo } from './types'

const initialState = {
  userInfo: undefined,
  managerInfo: undefined,
  usersInfo: undefined,
  usersList: [],
  usersValidatedByInfo: undefined,
}

export const userInfoSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    reset: () => initialState,
    setUserInfo(state, action: PayloadAction<userInfo>) {
      state.userInfo = action.payload
    },
    setManagerInfo(state, action: PayloadAction<managerInfo[]>) {
      state.managerInfo = action.payload
    },
    setUsersInfo(state, action: PayloadAction<managerInfo[]>) {
      state.usersInfo = action.payload
    },
    setUsersList(state, action: PayloadAction<userInfo[]>) {
      state.usersList = action.payload
    },
    setValidatedByUsersInfo(state, action: PayloadAction<userInfo[]>) {
      state.usersValidatedByInfo = action.payload
    },
  },
})

export const {
  reset, setUserInfo, setManagerInfo, setUsersInfo,
  setUsersList, setValidatedByUsersInfo,
} = userInfoSlice.actions

export default userInfoSlice.reducer
