import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Agenda } from './types'
import { agendaService } from './service'

const initialState = {
  agenda: undefined,
  teams: undefined,
}

export const agendaSlice = createSlice({
  name: 'agenda',
  initialState,
  reducers: {
    reset: () => initialState,
    setAgenda(state, action: PayloadAction<Agenda[]>) {
      state.agenda = agendaService.separateAbsencesByMonth(action.payload)
    },
    setTeams(state, action: PayloadAction<any>) {
      state.teams = action.payload
    },
  },
})

export const {
  setAgenda, setTeams,
} = agendaSlice.actions

export default agendaSlice.reducer
